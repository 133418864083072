import React, { SyntheticEvent, useEffect, useState } from 'react'
import { MenuSystemItem } from '../../../app/model/menu-system-item';
import { ApisService } from '../../../app/core/apis.service';
import { dataDecrypt, encrypt } from '../../../app/utility/encryption-helper';
import { MenuSystemUpdates } from '../../../app/core/menu-system-updates';
import { MenuSystemUpdateItem } from '../../../app/model/menu-system-update-item';
import { MenuSystemItemView } from './menu-system-item-view';

interface Props {
    type: 'system' | 'bu';
    bu?: string;
}
export const MenuSystem = (props: Props) => {
    const [menuSystemData, setMenuSystemData] = useState<MenuSystemItem[]>([]);
    const [canShowScuccess, setCanShowScuccess] = useState(false);
    useEffect(() => {
        loadMenuSystem();
    }, []);

    useEffect(() => {
        prepareMenuSystemUpdate();
    }, [menuSystemData]);

    const loadMenuSystem = async () => {
        const res = await ApisService.getOrganizationData('getOrgMenuSystem', '');
        if (res.data && res.data.data) {
            const menuData = JSON.parse(dataDecrypt(res.data.data));
            setMenuSystemData(menuData.menus);
        }
    }
    const onChangeVisibility = (event: SyntheticEvent, item: MenuSystemItem) => {
        const inputEl = event.target as HTMLInputElement;
        if (inputEl) {
            const detectedItem = MenuSystemUpdates.menuSystemItems.find(el => el.code === item.code);
            if (detectedItem) {
                detectedItem.enabled = inputEl.checked;
            }
        }
    }

    const prepareMenuSystemUpdate = () => {
        const preparedMenuSystem: MenuSystemUpdateItem[] = [];
        for (let index = 0; index < menuSystemData.length; index++) {
            const element = menuSystemData[index];
            if (props.type === 'system' || element.enabled) {
                preparedMenuSystem.push({
                    code: element.code,
                    enabled: element.enabled
                });
            }
            if (element.children) {
                prepareInnerMenuItem(element.children, preparedMenuSystem);
            }
        }
        console.log('prepared menu system ', preparedMenuSystem);
        MenuSystemUpdates.menuSystemItems = preparedMenuSystem;

    }

    const prepareInnerMenuItem = (menuItems: MenuSystemItem[], preparedMenuSystem: MenuSystemUpdateItem[]) => {
        for (let index = 0; index < menuItems.length; index++) {
            const element = menuItems[index];
            if (props.type === 'system' || element.enabled) {
                preparedMenuSystem.push({
                    code: element.code,
                    enabled: element.enabled
                });
            }
            if (element.children) {
                prepareInnerMenuItem(element.children, preparedMenuSystem);
            }
        }
    }

    const onSaveSystem = async () => {
        if (props.type === 'system') {
            const menuData = encrypt(JSON.stringify(MenuSystemUpdates.menuSystemItems));
            await ApisService.getOrganizationData('updateOrgMenuSystem', menuData);
        } else if (props.type === 'bu') {
            const orgFeatureReq = { bu: props.bu, features: MenuSystemUpdates.menuSystemItems.filter(el=>el.enabled).map(el => el.code) };
            const menuData = encrypt(JSON.stringify(orgFeatureReq));
            console.log('org feature data ', orgFeatureReq, props);
            
            await ApisService.getOrganizationData('updateOrgFeatures', menuData);
        }
        setCanShowScuccess(true);
    }

    const filterMenuSystem = () => {

    }

    return (
        <div className='p-5'>
            {
                menuSystemData?.map(el => <>
                    {(el.enabled || props.type === 'system') && <div className='ms-3' key={el.code}>
                        <input type="checkbox" name="" id="" className='me-3' onClick={(event) => onChangeVisibility(event, el)} defaultChecked={el.enabled} />
                        <span>{el.label}</span>
                        {
                            el.children && el.children.map(childEl => <MenuSystemItemView type={props.type} key={childEl.code} item={childEl}></MenuSystemItemView>)
                        }
                    </div>}
                </>)
            }
            {canShowScuccess && <div className='text-success text-center'>Saved successfully</div>}
            <div className='text-end'>
                <button className="btn btn-primary" onClick={onSaveSystem}>Save</button>
            </div>
        </div>
    )
}
