import React from 'react'
import { MenuSystem } from '../menu-system/menu-system'
import { useParams } from 'react-router-dom';

export const OrgDetails = () => {
  const routerParams = useParams();
  console.log('routerParams ', routerParams.org);

  return (
    <div>
      <MenuSystem bu={routerParams.org} type='bu'></MenuSystem>
    </div>
  )
}
