import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { LoginPage } from '../pages/login/login'
import { RegisterPage } from '../pages/login/register'
import { ForgotPasswordPage } from '../pages/login/forgot-password'
import { DashboardPage } from '../pages/Dashboard/dashboard'
import { InvitationPage } from '../pages/Dashboard/invitation/invitation'
import { InvitationForm } from '../pages/Dashboard/invitation/form'
import { ProductBulkupload } from '../pages/product-bulkupload'
import { MenuSystem } from '../pages/Dashboard/menu-system/menu-system'
import { OrganizationsList } from '../pages/Dashboard/organization/list'
import { OrgDetails } from '../pages/Dashboard/organization/org-details'
import { OrgMenuContainer } from '../pages/Dashboard/organization/org-menu-container'

interface Props {

}

export const RoutesPage = (props: Props) => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoginPage />}></Route>
                    <Route path="/login" element={<LoginPage />}></Route>
                    <Route path="/register" element={<RegisterPage />}></Route>
                    <Route path="/forgot-password" element={<ForgotPasswordPage />}></Route>
                    <Route path="/dashboard" element={<DashboardPage />}>
                        <Route path="invitation" element={<InvitationPage />}></Route>
                        <Route path="organizations" element={<OrgMenuContainer />}>
                            <Route path='' element={<OrganizationsList />}></Route>
                            <Route path='list' element={<OrgDetails />}></Route>
                            <Route path='org-details/:org' element={<OrgDetails />}></Route>
                        </Route>
                        <Route path='menu-system' element={<MenuSystem type='system' />}></Route>
                        {/* <Route path="/dashboard/invitation/form" element={<InvitationForm />}></Route>
                        <Route path='/dashboard/menu-system' element={<MenuSystem/>}></Route> */}
                    </Route>
                    <Route path='/products/bulkupload/:bu' Component={ProductBulkupload}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}
