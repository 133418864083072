import React, { useEffect, useState } from 'react'
import { Breadcum } from '../../../components/breadcum'
import { NavLink } from 'react-router-dom'
import { DataTable } from '../../../components/data-table';
import { OrganizationDataGridCols } from './data-grid-cols';
import { dataDecrypt, encrypt } from '../../../app/utility/encryption-helper';
import { ApisService } from '../../../app/core/apis.service';
import { Search } from '../../../components/search/search';

export const OrganizationsList = () => {

  const [loading, setLoading] = useState(false);
  const [breadcumInfo, setBreadcumInfo] = useState<any>([
    {
      label: "Organizations",
      value: "Organizations",
      routerLink: "/dashboard/organization",
      isActive: false,
    },
    {
      label: "User Name",
      value: "",
      routerLink: "",
      isActive: false,
    },
  ]);

  const [organizationData, setOrganizationData] = useState<any>([]);


  useEffect(() => {
    loadOrgs();
  }, []);

  async function loadOrgs(): Promise<void> {
    setLoading(true);
    try {
      let encrytedData = "";
      const res = await ApisService.getOrganizationData('getAll', encrytedData);
      const organizationData = JSON.parse(dataDecrypt(res.data.data));
      // console.log(organizationData);
      if (organizationData) {
        organizationData.results.forEach((element: any) => {
          element.link = `org-details/${element._id}`;
        });
        setOrganizationData(organizationData.results);
      }
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      console.error('Error in transaction:', error);
    }
  }

  return (


    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-12 text-end d-flex justify-content-between pl-8'>
              <div>
                <Breadcum breadcumList={breadcumInfo}></Breadcum>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-between my-1">
            <div className="col-md-6">
              <Search
              />
            </div>

          </div>
          <div className='row '>
            <div className='col-12 px-0'>
              {loading &&
                <div className="text-center p-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              }
              {!loading &&
                <>
                  <DataTable tableData={organizationData} TableCols={OrganizationDataGridCols} />
                </>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
