import React, { SyntheticEvent } from 'react'
import { MenuSystemItem } from '../../../app/model/menu-system-item'
import { MenuSystemUpdates } from '../../../app/core/menu-system-updates';

interface Props {
    item: MenuSystemItem;
    type: 'system' | 'bu';
}
export const MenuSystemItemView = (props: Props) => {
    const onChangeVisibility = (event: SyntheticEvent) => {
        const inputEl = event.target as HTMLInputElement;
        if (inputEl) {
            const detectedItem = MenuSystemUpdates.menuSystemItems.find(el => el.code === props.item.code);
            if (detectedItem) {
                detectedItem.enabled = inputEl.checked;
            }
        }
    }

    return (
        <>
            {(props.type === 'system' || props.item.enabled) && <div className='ms-5'>
                <input type="checkbox" name="" id="" className='me-3' onClick={(event) => onChangeVisibility(event)} defaultChecked={props.item.enabled} />
                <span>{props.item.label} <small className='text-primary'>({props.item.code})</small></span>
                {
                    props.item.children && props.item.children.map(childEl => <MenuSystemItemView type={props.type} key={childEl.code} item={childEl}></MenuSystemItemView>)
                }
            </div>}
        </>
    )
}
